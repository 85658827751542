<script lang="ts" setup>
  const { path } = useRoute()
  const isLoading = ref(false)

  const content = computed<any>(() => {
    return useAttrs().content
  })

  const defaultMetaImage = 'https://a.storyblok.com/f/236210/1200x627/3f0f29c4cc/company-share.jpg'

  const meta = content.value?.meta
  if (meta) {
    useSeoMeta({
      title: () => meta?.title,
      description: () => meta?.description,
      ogUrl: () => path,
      ogTitle: () => meta?.og_title || meta?.title,
      ogDescription: () => meta?.og_description || meta?.description,
      ogType: 'website',
      ogImage: () => meta?.og_image || defaultMetaImage,
      ogSiteName: 'Company Page',
      twitterTitle: meta?.twitter_title || meta?.title,
      twitterDescription: meta?.twitter_description || meta?.description,
      twitterImage: meta?.twitter_image || defaultMetaImage,
      twitterCard: 'summary'
    })
  }
</script>
<template>
  <StoryblokComponent
    v-if="!isLoading && content"
    :blok="content"
  />
</template>
